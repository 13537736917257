import React from "react"
import Dialog from "./Dialog"

export default function Modal({
  title,
  classTitle,
  children,
  className,
  open,
  onClose,
  onConfirm,
}) {
  if (!open) {
    return <></>
  }

  return (
    <Dialog open={open} onClose={onClose} className={className}>
      <h2
        className={classTitle ? classTitle : `text-2xl normal-case text-left`}
      >
        {title}
      </h2>
      <div className="text-base py-5 normal-case text-justify">{children}</div>
      <div className="flex justify-end">
        <div className="p-1">
          <button
            onClick={() => onClose()}
            className="inline-block px-6 py-3 border border-gray-200 text-lg text-gray-500 bg-white hover:bg-white focus:border-gray-300 active:bg-white rounded-sm transition ease-in-out duration-150 disabled:opacity-40 disabled:cursor-not-allowed"
            type="button"
          >
            Fermer
          </button>
        </div>
        {/* <div className="p-1">
          <button
            onClick={() => {
              onClose()
              onConfirm()
            }}
            className="bg-stone-900 hover:bg-stone-900-light text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
          >
            Oui
          </button>
        </div> */}
      </div>
    </Dialog>
  )
}
