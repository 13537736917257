import React from "react"
import { IoCloseOutline } from "react-icons/io5"

export default function Dialog({ children, open, onClose, className }) {
  if (!open) {
    return <></>
  }
  return (
    <>
      <div
        role="button"
        tabIndex={0}
        data-modal="overlay"
        className="fixed inset-0 z-40 overflow-auto bg-gray-900 bg-opacity-50 flex cursor-default"
        onClick={(e) =>
          e.target && e.target.dataset.modal === "overlay" && onClose()
        }
        onKeyDown={(e) => e.keyCode === 27 && onClose()}
      >
        <div
          className={`relative pt-14 pb-10 px-6 z-50 bg-stone-50 border-2 border-stone-100 m-auto flex-col flex rounded-lg ${
            className ? className : "w-full max-w-lg sm:max-w-xl md:max-w-7xl"
          }`}
        >
          <div className="text-gray-700">{children}</div>
          <span className="absolute top-0 right-4">
            <button
              type="button"
              onClick={() => onClose()}
              className="text-gray-500 focus:outline-none focus:border-none hover:bg-gray-400 hover:bg-opacity-25 p-2 rounded-sm inline-flex items-center"
            >
              <IoCloseOutline size="30" color="inherit" />
            </button>
          </span>
        </div>
      </div>
    </>
  )
}
