import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import GImage from "../components/GImage"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Footer from "../components/Footer"
import StyledTitle from "../components/StyledTitle"
import BaseContext from "../context/baseContext"
import styled from "@emotion/styled"

const InsetBoxShadow = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  box-shadow: 0px -40px 160px 60px rgba(0, 0, 0, 0.6) inset;
  -webkit-box-shadow: 0px -40px 160px 60px rgba(0, 0, 0, 0.6) inset;
  -moz-box-shadow: 0px -40px 160px 60px rgba(0, 0, 0, 0.6) inset;
`

const Histoire = ({ data: gqlData }) => {
  const headerRef = React.useContext(BaseContext)
  const { directus, batisse, arche, vigne2, degustation4, tracteur } = gqlData
  return (
    <>
      <Seo
        title="L'histoire du vignoble"
        description="Notre vignoble est conduit selon les préceptes de la culture biologique et du calendrier lunaire afin de favoriser l’activité des sols et de respecter la biodiversité qui nous entoure."
      />
      <section className="relative" ref={headerRef}>
        <InsetBoxShadow />
        <GImage
          objectPosition="50% 100%"
          objectFit="cover"
          className="w-full h-80vh"
          imgClassName="w-full h-80vh"
          src={batisse}
          alt={`Photo de la batisse du vignoble de Jape-loup`}
        />
      </section>
      <div className="items-center pb-32">
        <div className="pt-12 pb-6 text-center" id="histoire">
          <AnchorLink title="Voir l'histoire" to={`/histoire#histoire`}>
            <h1 className="text-5xl font-bold">Histoire</h1>
          </AnchorLink>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 px-0 lg:px-6 py-4">
          <div className="place-self-center">
            <div className="bg-stone-50 border-2 border-stone-100 px-8 py-4 mb-8 max-w-c_large mx-auto text-xl text-justify">
              <div
                className="py-10 text-gray-500"
                dangerouslySetInnerHTML={{
                  __html: `${directus.story.description}`,
                }}
              />
            </div>
          </div>
          <div className="">
            <GImage
              src={arche}
              style={{ display: "block" }}
              className="w-full h-full"
            />
          </div>
        </div>

        <div>
          <GImage src={vigne2} className="w-full h-80 object-contain" />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 px-0 lg:px-6 py-8">
          <div>
            <div className="grid grid-cols-1 gap-8">
              <div>
                <div className="bg-stone-50 border-2 border-stone-100 pt-12 px-8 max-w-c_large mx-auto text-xl text-justify">
                  <StyledTitle
                    darkTheme={false}
                    text={directus.story.title}
                    component="h3"
                    className="h1-variant font-bold inline-block mx-auto"
                  />

                  <div
                    className="py-10 text-gray-500"
                    dangerouslySetInnerHTML={{
                      __html: `${directus.story.description2}`,
                    }}
                  />
                  {/* <TestimonialsCarousel slides={directus.story.Slide} /> */}
                </div>
              </div>
              <div>
                <GImage src={degustation4} className="w-full object-contain" />
              </div>
            </div>
          </div>
          <div>
            <div>
              <div className="">
                <GImage src={tracteur} className="w-full object-contain" />
                <div className="py-8">
                  <div className="bg-stone-50 border-2 border-stone-100 px-8 py-10 max-w-c_large mx-auto text-xl text-justify">
                    <div
                      className="pt-4 text-gray-500"
                      dangerouslySetInnerHTML={{
                        __html: `${directus.story.description3}`,
                      }}
                    />
                    <div className="pb-2 flex justify-end">
                      <svg
                        className="transform rotate-180"
                        width="110.7"
                        height="25.36"
                        viewBox="0 0 114 29"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.08697 26.9618C2.08697 26.9618 56.7739 -10.2252 112.797 5.45215"
                          stroke="#222222"
                          strokeOpacity="0.8"
                          strokeWidth="1.70018"
                          strokeLinecap="square"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {directus.pages_settings.mentions && (
        <Footer mentions={directus.pages_settings.mentions} />
      )}
    </>
  )
}

export default Histoire

export const pageQuery = graphql`
  query {
    # locales: allLocale(filter: { language: { eq: $language } }) {
    #   edges {
    #     node {
    #       ns
    #       data
    #       language
    #     }
    #   }
    # }
    directus: allDirectus {
      story {
        title
        description
        description2
        description3
      }
      pages_settings {
        mentions
      }
    }
    batisse: file(relativePath: { eq: "batisse.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 80
          placeholder: TRACED_SVG
          tracedSVGOptions: { color: "#f3f3ed" }
        )
      }
    }
    arche: file(relativePath: { eq: "arche.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 80
          placeholder: TRACED_SVG
          tracedSVGOptions: { color: "#f3f3ed" }
        )
      }
    }
    vigne2: file(relativePath: { eq: "les-vins/vigne2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 80
          placeholder: TRACED_SVG
          tracedSVGOptions: { color: "#f3f3ed" }
        )
      }
    }
    tracteur: file(relativePath: { eq: "tracteur.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 80
          placeholder: TRACED_SVG
          tracedSVGOptions: { color: "#f3f3ed" }
        )
      }
    }
    degustation4: file(relativePath: { eq: "les-vins/degustation4.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 80
          placeholder: TRACED_SVG
          tracedSVGOptions: { color: "#f3f3ed" }
        )
      }
    }
  }
`
