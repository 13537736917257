import React from "react"
import whiteLine from "../images/svg/whiteLine.svg"
import blackLine from "../images/svg/blackLine.svg"
import styled from "@emotion/styled"

const SvgLine = styled.span`
  &:before {
    position: absolute;
    display: inline-flex;
    left: -20px;
    top: -20px;
    content: "";
    background-image: ${(props) => props.line};
    background-size: contain;
    background-repeat: no-repeat;
    width: 110.71px;
    height: 25.37px;
  }
`
const StyledTitle = ({ text, component, className, darkTheme }) => {
  const line = darkTheme ? whiteLine : blackLine
  const color = darkTheme ? "light-300" : "primary"

  return (
    <>
      {text && component === "h1" && (
        <h1
          className={`relative inline-flex justify-center font-bold text-center sm:text-left text-${color} ${className}`}
        >
          <SvgLine line={`url(${line})`} />
          {text}
        </h1>
      )}
      {text && component === "h2" && (
        <h2
          className={`relative inline-flex justify-center w-auto text-center sm:text-left text-${color} ${className}`}
        >
          <SvgLine line={`url(${line})`} />
          {text}
        </h2>
      )}
      {text && component === "h3" && (
        <h3
          className={`relative inline-flex justify-center text-center sm:text-left text-${color} ${className}`}
        >
          <SvgLine line={`url(${line})`} />
          {text}
        </h3>
      )}
      {text && component === "p" && (
        <p
          className={`relative inline-flex justify-center text-center sm:text-left text-${color} ${className}`}
        >
          <SvgLine line={`url(${line})`} />
          {text}
        </p>
      )}
    </>
  )
}

export default StyledTitle
