import React, { useState } from "react"
import Logo from "./Logo"
import Modal from "./Modal"
import SocialNetworks from "./SocialNetworks"
import { Link } from "gatsby"

const Footer = ({ mentions }) => {
  const [openModal, setOpenModal] = useState(false)
  const [isReady, setIsReady] = useState(false)
  setTimeout(() => {
    setIsReady(true)
  }, 500)

  return (
    isReady && (
      <footer className="grid grid-cols-1 md:grid-cols-3 gap-8 sm:gap-4 bg-stone-900 font-sans pt-12 pb-8 px-8">
        <div>
          <div className="flex justify-center h-full">
            <div className="py-2 self-center">
              <span className="block mx-auto text-center uppercase text-gray-300 pt-2 pb-2 mt-2 text-xs leading-6">
                &copy; {new Date().getFullYear()} - Tous droits réservés -
                <div>
                  <span
                    tabIndex={0}
                    role="button"
                    onClick={() => setOpenModal(true)}
                    onKeyDown={() => setOpenModal(true)}
                  >
                    Mentions légales
                  </span>
                  {" - "}
                  <span className="my-4 uppercase text-gray-300 text-xs leading-6">
                    <Link to="/contact" title="Contactez-nous">
                      Contactez-nous
                    </Link>
                  </span>
                  <Modal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    classTitle="text-3xl normal-case text-center"
                    title="Mentions légales"
                  >
                    {mentions && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${mentions}`,
                        }}
                      />
                    )}
                    <div className="col-span-1 md:col-span-3">
                      <p className="pt-8 pb-2 uppercase">
                        L'abus d'alcool est dangereux pour la santé, à consommer
                        avec modération
                      </p>
                    </div>
                  </Modal>
                </div>
              </span>
            </div>
          </div>
        </div>

        <div>
          <div className="flex justify-center h-full">
            <div className="py-2 self-center">
              <Logo
                mod="simple"
                color="#d6d3d1"
                color2="#cccccc"
                height="100%"
              />
            </div>
          </div>
        </div>

        <div>
          <div className="flex justify-center h-full">
            <div className="py-2 self-center">
              {/* <Typography variant="body1" className={classes.typography2}>Retrouvez-nous bientôt sur Instagram !</Typography> */}
              <SocialNetworks
                icon={true}
                Instagram="https://www.instagram.com/vignoble_de_jape_loup/"
                Facebook="https://www.facebook.com/Vignoble-de-Jape-loup-103718091432387/"
              />
            </div>
          </div>
        </div>
      </footer>
    )
  )
}

export default Footer
